import { type FC, useMemo } from 'react';
// @TODO - remove these legacy imports
import { Nav, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  faCloudUploadAlt,
  faExclamationTriangle,
  faSpinner,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRegisterActions } from 'kbar';

import { randomId } from 'src/utils/string';
import { useSyncContext } from '../hooks';
import { SyncContextState } from '../types';

function pluralise(word: string, count: number) {
  return count === 1 ? word.replace(/s$/i, '') : word.replace(/s?$/i, 's');
}

export const SyncStatus: FC = () => {
  const { useSyncStatus, statistics, resetLocalDatabase, deleteDatabase, closeDbConnection } =
    useSyncContext();
  const { syncPercentage, cachingStoreCount, status, transactionCount } = useSyncStatus();

  const syncActions = useMemo(
    () => [
      {
        id: randomId(),
        name: 'Log entity statistics',
        section: 'Developer',
        priority: -1,
        perform: async () => {
          const stats = await statistics();
          console.table(stats);
        },
      },
      {
        id: randomId(),
        name: 'Reset local database',
        section: 'Developer',
        perform: async () => {
          await resetLocalDatabase();
          window.location.reload();
        },
      },
      {
        id: randomId(),
        name: 'Delete local database',
        section: 'Developer',
        perform: async () => {
          await deleteDatabase();
          window.location.reload();
        },
      },
      {
        id: randomId(),
        name: 'Close db connection',
        section: 'Developer',
        // eslint-disable-next-line @typescript-eslint/require-await
        perform: async () => {
          closeDbConnection();
        },
      },
    ],
    [resetLocalDatabase, statistics, deleteDatabase, closeDbConnection]
  );

  useRegisterActions(syncActions, []);

  switch (status) {
    case SyncContextState.BootstrapError:
    case SyncContextState.Failed: {
      const tooltip = (
        <Popover id="SyncStatus">
          <Popover.Title as="h4">VPN connected but API unreachable</Popover.Title>
          <Popover.Content>
            {transactionCount > 0 ? (
              <>Your {transactionCount} changes will be saved once the error has been resolved.</>
            ) : (
              <>Any changes you make will be saved once the error has been resolved.</>
            )}
          </Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Nav.Link data-testid="sync-status">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="sm"
              style={{ marginRight: '5px' }}
            />
            {transactionCount > 0 ? <>Failed ({transactionCount})</> : <>Failed</>}
          </Nav.Link>
        </OverlayTrigger>
      );
    }

    case SyncContextState.Offline: {
      const tooltip = (
        <Popover id="SyncStatus">
          <Popover.Title as="h4">You are offline</Popover.Title>
          <Popover.Content>
            {transactionCount > 0 ? (
              <>
                Your {transactionCount} {pluralise('change', transactionCount)} will be saved when
                you regain connectivity.
              </>
            ) : (
              <>Any changes you make will be saved after you regain connectivity.</>
            )}
          </Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Nav.Link data-testid="sync-status">
            <FontAwesomeIcon icon={faUnlink} className="fa-1x" style={{ marginRight: '5px' }} />
            {transactionCount > 0 ? <>Offline ({transactionCount})</> : <>Offline</>}
          </Nav.Link>
        </OverlayTrigger>
      );
    }

    case SyncContextState.ServerOffline: {
      const tooltip = (
        <Popover id="SyncStatus">
          <Popover.Title as="h4">VPN is disconnected</Popover.Title>
          <Popover.Content>
            {transactionCount > 0 ? (
              <>
                Your {transactionCount} {pluralise('change', transactionCount)} will be saved once a
                vpn connection to the server is established.
              </>
            ) : (
              <>
                Any changes you make will be saved once a VPN connection to the server has been
                established.
              </>
            )}
          </Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Nav.Link data-testid="sync-status">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="sm"
              style={{ marginRight: '5px' }}
            />
            {transactionCount > 0 ? (
              <>VPN Disconnected ({transactionCount})</>
            ) : (
              <>VPN Disconnected</>
            )}
          </Nav.Link>
        </OverlayTrigger>
      );
    }

    case SyncContextState.Caching: {
      const tooltip = (
        <Popover id="SyncStatus">
          <Popover.Title as="h4">Caching data locally</Popover.Title>
          <Popover.Content>Persisting {cachingStoreCount} data stores locally.</Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Nav.Link data-testid="sync-status">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-1x fa-spin fa-pulse"
              style={{ marginRight: '5px' }}
            />
            Caching ({syncPercentage}%)
          </Nav.Link>
        </OverlayTrigger>
      );
    }

    case SyncContextState.Saving: {
      const tooltip = (
        <Popover id="SyncStatus">
          <Popover.Title as="h4">Saving your changes</Popover.Title>
          <Popover.Content>
            Your {transactionCount} {pluralise('change', transactionCount)} are being saved.
          </Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <Nav.Link data-testid="sync-status">
            <FontAwesomeIcon
              icon={faCloudUploadAlt}
              className="fa-1x"
              style={{ marginRight: '5px' }}
            />
            Saving ({transactionCount})
          </Nav.Link>
        </OverlayTrigger>
      );
    }

    default:
      return null;
  }
};
