import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import type {
  TriggerCheckRunResult,
  TriggerValidationCallback,
  ValidationCheck,
} from 'src/checks/types';
import { getValidationCheckMUIColor } from 'src/checks/utils';
import { SyncContextState, useSyncContext } from 'src/sync';
import { ValidationIcon } from './ValidationIcon';

const LIST_ITEM_PADDING = 10;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${LIST_ITEM_PADDING}px;
  margin: 0px;
  border-bottom: ${({ theme }) => `1px solid ${theme.darkGrey}`};
`;

interface Props {
  check: ValidationCheck;
  onClick?: (checkSlug: string | undefined) => void;
  onValidate: TriggerValidationCallback;
  isSummaryPage?: boolean;
  triggerCheckRunResults: TriggerCheckRunResult[];
}

export const getCheckStatusMessage = (
  checkStatus: ValidationCheck['checkStatus'],
  errorCount: number | undefined
) => {
  if (checkStatus === 'Success') {
    return 'No issues found';
  }

  if (checkStatus === 'Warning') {
    return `${errorCount ?? 'Some'} issues found`;
  }

  if (checkStatus === 'NotRun') {
    return "This check hasn't been run yet";
  }

  return null;
};

const getCheckLastRunDateDisclaimers = (
  timestamp: number | undefined | string,
  checkStatus: ValidationCheck['checkStatus']
):
  | {
      disclaimer: string;
      tipDisclaimer: string;
    }
  | undefined => {
  if (!timestamp) return undefined;

  const lastRunDate = new Date(timestamp);
  if (Number.isNaN(lastRunDate.getTime())) return undefined;

  const currentDate = new Date();
  const millisecondsPerMinute = 60 * 1000;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;
  const millisecondsPerMonth = 30 * millisecondsPerDay;
  const millisecondsPerYear = 12 * millisecondsPerMonth;

  let disclaimer = checkStatus === 'Success' ? 'Succeeded ' : 'Run ';

  const timeDifference = currentDate.getTime() - lastRunDate.getTime();

  // if there is year differences, return X years ago
  if (timeDifference / millisecondsPerYear >= 1) {
    disclaimer += `${Math.floor(timeDifference / millisecondsPerYear)} year(s) ago.`;
  }
  // if there is month differences, return X months ago
  else if (timeDifference / millisecondsPerMonth >= 1) {
    disclaimer += `${Math.floor(timeDifference / millisecondsPerMonth)} month(s) ago.`;
  }
  // if there is day differences, return X days ago
  else if (timeDifference / millisecondsPerDay >= 1) {
    disclaimer += `${Math.floor(timeDifference / millisecondsPerDay)} day(s) ago.`;
  }
  // if there is hour differences, return X hours ago
  else if (timeDifference / millisecondsPerHour >= 1) {
    disclaimer += `${Math.floor(timeDifference / millisecondsPerHour)} hour(s) ago.`;
  }
  // if there is minute differences, return X minutes ago
  else if (timeDifference / millisecondsPerMinute >= 1) {
    disclaimer += `${Math.floor(timeDifference / millisecondsPerMinute)} minutes(s) ago.`;
  } else {
    disclaimer += 'now.';
  }

  return {
    disclaimer,
    tipDisclaimer: lastRunDate.toLocaleString('en-GB'),
  };
};

export const CheckHeader = ({
  check,
  onValidate,
  onClick,
  isSummaryPage,
  triggerCheckRunResults,
}: Props) => {
  const { useSyncStatus } = useSyncContext();
  const { status, transactionCount } = useSyncStatus();

  const { checkName, description, checkStatus, lastUpdatedDate, errorCount, checkId } = check;
  const message = getCheckStatusMessage(checkStatus, errorCount);
  const isChecklistHeader = checkStatus === 'None';

  const checkLastRunDateDisclaimers =
    check.checkStatus !== 'NotRun'
      ? getCheckLastRunDateDisclaimers(lastUpdatedDate, check.checkStatus)
      : undefined;

  // 1.Don’t allow checks to be run when offline
  // 2.Don’t allow checks to be run if the transaction queue has failed or in progress.
  const isValidateButtonEnabled = status !== SyncContextState.Offline && transactionCount === 0;

  const handleClick = () => {
    if (onClick) {
      onClick(check.checkSlug);
    }
  };

  return (
    <StyledListItem>
      {!isChecklistHeader && <ValidationIcon status={checkStatus} large />}
      <Typography variant="inherit" onClick={handleClick} sx={headerStyles}>
        <Typography variant="inherit" data-testid="check-header-title" fontWeight="bold">
          {isChecklistHeader ? description : checkName}
        </Typography>
        {!isChecklistHeader && (
          <Typography variant="inherit" fontStyle="italic" marginBottom="10px">
            {description}
          </Typography>
        )}
        {message && (
          <>
            <Typography
              variant="inherit"
              component="span"
              sx={theme => ({
                color: getValidationCheckMUIColor({ theme, status: check.checkStatus }),
                ...checkStatusMessageStyles,
              })}
              data-testid="check-header-errormessage"
            >
              {message}
            </Typography>
            {checkLastRunDateDisclaimers && (
              <Tooltip title={checkLastRunDateDisclaimers.tipDisclaimer}>
                <span>{checkLastRunDateDisclaimers.disclaimer}</span>
              </Tooltip>
            )}
          </>
        )}

        {(isChecklistHeader || !isSummaryPage) &&
          triggerCheckRunResults.find(item => !item.isSucceeded) && (
            <Typography
              variant="inherit"
              fontStyle="italic"
              fontWeight="bold"
              marginBottom="10px"
              sx={theme => ({
                color: getValidationCheckMUIColor({ theme, status: 'Warning' }),
                ...checkStatusMessageStyles,
              })}
            >
              Triggering of the check runs failed. You can retry the checks by re-running them.
            </Typography>
          )}
      </Typography>

      {isChecklistHeader && (
        <Button
          variant="contained"
          onClick={() => onValidate(checkId)}
          disabled={!isValidateButtonEnabled}
          sx={validateAllButtonStyles}
        >
          Re-run all checks
        </Button>
      )}

      {!isSummaryPage && (
        <Button
          variant="contained"
          sx={theme => ({
            backgroundColor: theme.palette.grey.A400,
          })}
          onClick={() => onValidate(checkId)}
          disabled={!isValidateButtonEnabled}
        >
          Re-run this check
        </Button>
      )}
    </StyledListItem>
  );
};

const headerStyles = {
  cursor: 'pointer',
  flexGrow: 1,
};

const checkStatusMessageStyles = {
  paddingRight: '10px',
};

const buttonStyles = {
  border: '1px solid transparent',
  padding: '5px 10px',
  borderRadius: '4px',
  height: '3rem',
  margin: 'auto 0',
};

const validateAllButtonStyles = {
  ...buttonStyles,
  // opacity: ${({ disabled }) => (disabled ? 0.5 : 1)},
  // cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')},
};
