import type {
  FilterModel,
  unsafe_GetContextMenuItemsParams,
  unsafe_RowNode,
} from 'src/components/DataGrid';
import { COLLECTED_YEAR } from 'src/constants';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { BrandLineTypes } from 'src/shared/types';
import { goToStep1, showAddModal } from 'src/store/features/brandLineAdd/actions';
import { useSyncContext } from 'src/sync';
import type { Entity } from 'src/types/Entity';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type CategoriesEntity } from 'src/types/entity/Categories';
import { type Dictionary } from 'src/types/MappedTypes';
import { type View } from 'src/views/types';
import {
  ContextMenuActionType,
  createContextMenu,
  type GridContextMenuItem,
} from 'src/views/utils/contextmenu';
import { BRAND_LINE, DISTRIBUTOR } from './BrandSalesTableViewPage';

interface GetContextMenuItemsParams {
  params: unsafe_GetContextMenuItemsParams<Entity>;
  canMaintainRecords?: boolean | undefined;
  canWriteRecords?: boolean | undefined;
  canRemoveRecords?: boolean | undefined;
  dispatch: (args: unknown) => void;
  setDeleteModalVisible: (arg: boolean) => void;
  setSelectedNode: (arg: Partial<unsafe_RowNode>) => void;
  onSwapAction: () => void;
  view?: View | undefined;
  categories: CategoriesEntity[];
}

export const getSelectedRows = (params: unsafe_GetContextMenuItemsParams<Entity>) => {
  const filterModel = Object.values(params.api.getFilterModel());

  if (filterModel.length > 0) {
    const filteredSelectedRows: DenormalizedBrandSales[] = [];

    params.api.forEachNodeAfterFilter(node => {
      if (node.isSelected() && node.data) {
        filteredSelectedRows.push(node.data as DenormalizedBrandSales);
      }
    });
    return filteredSelectedRows;
  }

  return params.api.getSelectedRows() as DenormalizedBrandSales[];
};

export const getContextMenuItems = ({
  params,
  canMaintainRecords,
  canWriteRecords,
  canRemoveRecords,
  dispatch,
  setDeleteModalVisible,
  setSelectedNode,
  onSwapAction,
  view,
  categories,
}: GetContextMenuItemsParams) => {
  let gridContextMenuItems: GridContextMenuItem[] = [];

  if (canWriteRecords) {
    const preselectedCategory = workOutPreselectedCategory(view?.filter, categories);

    gridContextMenuItems = [
      {
        name: `Add ${BRAND_LINE}`,
        actionType: ContextMenuActionType.ADD,
        action: () => {
          dispatch(showAddModal());
          dispatch(goToStep1({ preselectedCategory }));
        },
        tooltip: `Add ${BRAND_LINE}`,
        isMainMenuItem: true,
      },
    ];

    if (!params.node?.group) {
      const chosenColumn: string = params.column?.getColDef().headerName?.toLowerCase() ?? '';

      if ([BRAND_LINE, DISTRIBUTOR].includes(chosenColumn)) {
        const actionType = ContextMenuActionType.SWAP;
        const name = `Swap ${chosenColumn}`;
        const tooltip = `Swap ${chosenColumn}`;

        gridContextMenuItems.push({ actionType, name, action: onSwapAction, tooltip });
      }
    }
  }

  if (canRemoveRecords) {
    if (!params.node?.group) {
      gridContextMenuItems.push({
        name: `Remove ${BRAND_LINE}`,
        actionType: ContextMenuActionType.DELETE,
        action: () => {
          if (params.node) {
            setSelectedNode({ data: params.node.data });
            setDeleteModalVisible(true);
          }
        },
        tooltip: `Remove ${BRAND_LINE}`,
      });
    }
  }

  if (canMaintainRecords) {
    const selectedRows = getSelectedRows(params);
    const selectedRowData = params.node?.data as DenormalizedBrandSales | undefined;

    if (selectedRows.length === 0 && selectedRowData && !selectedRowData.acknowledgedByResearcher) {
      selectedRows.push({ ...selectedRowData });
    }

    const contextMenuItemName =
      selectedRows.length > 1
        ? `Approve all ${COLLECTED_YEAR} volumes`
        : `Approve ${COLLECTED_YEAR} volume`;

    gridContextMenuItems.push({
      name: contextMenuItemName,
      actionType: ContextMenuActionType.ACKNOWLEDGE,
      action: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { updateEntities } = useSyncContext();

        const acknowledgedBrandSales: Partial<BrandSalesEntity>[] = selectedRows.map(row => {
          return {
            saleGUID: row.saleGUID,
            acknowledgedByResearcher: true,
          };
        });

        void updateEntities('brandSales', acknowledgedBrandSales);
      },
      tooltip: contextMenuItemName,
      isMainMenuItem: false,
      disabled: selectedRows.length === 0,
    });
  }

  return createContextMenu(canRemoveRecords, params, gridContextMenuItems);
};

export const workOutPreselectedCategory = (
  filters: FilterModel | undefined,
  categories: CategoriesEntity[]
) => {
  const filter = filters?.[0];
  const filterValue = (filter?.value as number[] | undefined)?.[0];

  if (filter && filterValue) {
    if (filter.field === 'category5Id') {
      const category5 = categories.find(({ level, id }) => level === 5 && id === filterValue);
      const category4 = categories.find(
        ({ level, id }) => level === 4 && id === category5?.parentId
      );
      const category3 = categories.find(
        ({ level, id }) => level === 3 && id === category4?.parentId
      );
      const category2 = categories.find(
        ({ level, id }) => level === 2 && id === category3?.parentId
      );

      return category2?.id ? `2-${category2.id}` : undefined;
    }
    if (filter.field === 'category4Id') {
      const category4 = categories.find(({ level, id }) => level === 4 && id === filterValue);
      const category3 = categories.find(
        ({ level, id }) => level === 3 && id === category4?.parentId
      );
      const category2 = categories.find(
        ({ level, id }) => level === 2 && id === category3?.parentId
      );
      return category2?.id ? `2-${category2.id}` : undefined;
    }
    if (filter.field === 'category3Id') {
      const category3 = categories.find(({ level, id }) => level === 3 && id === filterValue);
      const category2 = categories.find(
        ({ level, id }) => level === 2 && id === category3?.parentId
      );
      return category2?.id ? `2-${category2.id}` : undefined;
    }
    if (filter.field === 'category2Id') {
      const category2 = categories.find(({ level, id }) => level === 2 && id === filterValue);
      return category2?.id ? `2-${category2.id}` : undefined;
    }
    if (filter.field === 'category1Id') {
      const category1 = categories.find(({ level, id }) => level === 1 && id === filterValue);
      return category1?.id ? `1-${category1.id}` : undefined;
    }
  }
  return;
};

export const dataSanityCheck = (brandSales: DenormalizedBrandSales[]) => {
  brandSales.forEach(sale => {
    if (
      sale.brandLineTypeId === BrandLineTypes.FORECAST &&
      Object.keys(sale.volume).some(year => Number(year) <= COLLECTED_YEAR)
    ) {
      console.warn(`Forecast Sale has historical volume data. ${sale.saleGUID}`);
    }
    if (
      sale.brandLineTypeId !== BrandLineTypes.FORECAST &&
      Object.keys(sale.volume).some(year => Number(year) > COLLECTED_YEAR)
    ) {
      console.warn(`Non-forecast Sale has forecast volume data. ${sale.saleGUID}`);
    }
  });
};

const isObjectLiteral = (value: unknown): value is Dictionary => {
  const isObject = typeof value === 'object';
  const isNullObject = value === null;
  const isArrayObject = Array.isArray(value);
  const isDateObject = value instanceof Date;

  return isObject && !isNullObject && !isArrayObject && !isDateObject;
};

export const recursivelyAssignDenormalizedBrandSaleDataToNode = (
  from: DenormalizedBrandSales | Dictionary,
  to: DenormalizedBrandSales | Dictionary
) => {
  Object.entries(from).forEach(([key, value]) => {
    if (isObjectLiteral(value) && to[key]) {
      recursivelyAssignDenormalizedBrandSaleDataToNode(value, to[key] as typeof value);
    } else {
      to[key] = value;
    }
  });
};
